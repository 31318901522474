import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import moment from "moment/moment";
import {
  Alert,
  AlertTitle,
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { BiVideoRecording } from "react-icons/bi";
import { FaRegCircleStop } from "react-icons/fa6";
import { FaCamera } from "react-icons/fa";
import { MdCamera } from "react-icons/md";
import { MdCancel } from "react-icons/md";
import { MdFlipCameraIos } from "react-icons/md";
import Webcam from "react-webcam";
import { ClipLoader } from "react-spinners";
import { useReactMediaRecorder } from "react-media-recorder";

import baseUrl from "../../constants/constant.json";

// Component
import Header from "../common/Header/Header";
import PatientPrakrutiPage from "./PatientPrakrutiPage";
import GynecPage from "./GynecPage";
import HetuParikshaPage from "./HetuParikshaPage";
import RogaParikshaPage from "./RogaParikshaPage";
import VitalCriteriaPage from "./VitalCriteriaPage";
import {
  addPatientData,
  getSinglePatientData,
  updatePatientData,
  uploadImage,
  uploadVideo,
} from "../../services/Patient/PatientService";

// json file
import prakrutiData from "./findPrakruti.json";
import hetuParikshaData from "./hetuPariksha.json";
import rogaParikshaData from "./rogaPariksha.json";
import { DropzoneArea } from "material-ui-dropzone";
import { useCallback } from "react";
import axios from "axios";
import { getToken } from "../../utils/getToken";

const mainHeading = {
  color: "#424242",
  fontSize: "30px",
  fontWeight: "700",
};

const dialogBoxName = {
  color: "#2F7539",
  cursor: "pointer",
  margin: "10px",
};

const saveButton = {
  "&:hover": {
    backgroundColor: "#2F7539",
  },
  margin: "10px",
  width: "150px",
  backgroundColor: "#2F7539",
};

const cancelButton = {
  "&:hover": {
    border: "1px solid #2F7539",
    backgroundColor: "none",
  },
  margin: "10px",
  width: "150px",
  border: "1px solid #2F7539",
  color: "#2F7539",
};

const imageButton = {
  "&:hover": {
    backgroundColor: "#2F7539",
  },
  textTransform: "capittalize",
  backgroundColor: "#2F7539",
  marginRight: "10px",
};

const imgHeading = {
  color: "424242",
  fontSize: "20px",
  fontWeight: "700",
  texAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginY: "10px",
};

const dialogHeading = {
  color: "#424242",
  fontSize: "25px",
  fontWeight: "700",
};

const dialogBoxStyle = {
  backgroundColor: "#969DA2",
  color: "#424242",
  fontSize: "18px",
  fontWeight: "700",
  padding: "5px",
};

const boxHeading = {
  color: "#2F7539",
  fontSize: "24px",
  fontWeight: "700",
  padding: "10px",
};

const boxContentMain = {
  color: "#424242",
  fontSize: "18px",
};

const boxContent = {
  color: "424242",
  fontSize: "18px",
  marginRight: "15px",
  fontWeight: "700",
};

const labelStyle = {
  marginX: "0px",
};

const boxStyle = {
  backgroundColor: "#F1F1F1",
  height: "auto",
  width: "100%",
  paddingBottom: "25px",
  borderRadius: "10px",
  marginBottom: "10px",
};

const uploadFilesStyle = {
  "&:hover": {
    border: "none",
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  border: "none",
  textTransform: "none",
  marginX: "10px",
  backgroundColor: "transparent",
  boxShadow: "none",
};

const clearButton = {
  backgroundColor: "#292421",
  color: "#ebebeb",
  borderRadius: "5px",
};

const dashboard = {
  maxHeight: "auto",
  position: "absolute",
  backgroundColor: "#F5F5F5",
  top: 50,
  left: "260px",
  width: "81%",
  borderRadius: "3px",
};

const linkStyle = {
  textDecoration: "none",
  listStyle: "none",
  color: "#2F7539",
};

const dropContainerStyle = {
  position: "relative",
  display: "flex",
  gap: "10px",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "200px",
  padding: "20px",
  borderRadius: "10px",
  border: "2px dashed #555",
  color: "#444",
  cursor: "pointer",
  transition: "background .2s ease-in-out, border .2s ease-in-out",
};

const dropTitleStyle = {
  color: "#444",
  fontSize: "20px",
  fontWeight: "bold",
  marginBottom: "10px",
};

const selectExcercis = [
  { label: "Walking" },
  { label: "Cycling" },
  { label: "Running or jogging" },
  { label: "Yoga" },
  { label: "Swimming" },
  { label: "Fitness classes" },
  { label: "Aerobic exercise" },
  { label: "Meditation" },
];

const initialDataJson = Array.from({ length: prakrutiData.length }, () => ({
  vata: 0,
  pitta: 0,
  kapha: 0,
}));

function AddPatient(props) {
  const [open, setOpen] = useState(false);
  const [validateError, setValidateError] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [openVitalCriteria, setOpenVitalCriteria] = useState(false);
  const [openGynecDialog, setOpenGynecDialog] = useState(false);
  const [hetuParikshaOpen, setHetuParikshaOpen] = useState(false);
  const [rogaParikshaOpen, setRogaParikshaOpen] = useState(false);
  const [patientData, setPatientData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    birthDate: null,
    ageYear: null,
    ageMonth: null,
    gender: "",
    ageGroup: "",
    mobileNumber: null,
    email: "",
    weight: null,
    idNumber: "",
    address: "",
    birthPlace: "",
    refName: "",
    refMobileNo: "",
    photos: [],
    videos: [],
    prakruti: "",
    refMedia: "",
    formData: [],
  });
  const [dataJson, setDataJson] = useState(initialDataJson);
  const [vataCount, setVataCount] = useState(0);
  const [pittaCount, setPittaCount] = useState(0);
  const [kaphaCount, setKaphaCount] = useState(0);
  const [selectedValues, setSelectedValues] = useState({});

  const navigate = useNavigate();
  const isVatapittaKapha = localStorage.getItem("maxPrakrutiField");
  const { patientId } = useParams();
  const user = localStorage.getItem("user");

  // add patient textField value change
  const handleAddPatient = (ele) => {
    const updatedData = {
      ...patientData,
      birthDate:
        ele.target.name === "ageYear" || ele.target.name === "ageMonth"
          ? null
          : patientData.birthDate,
      [ele.target.name]: ele.target.value,
    };

    setPatientData(updatedData);
  };

  const handleDatePicker = (type, newDate) => {
    const currentDate = new Date(newDate);
    const dateString = currentDate.toLocaleDateString("en-US");
    const formattedDate = moment(dateString).format("YYYY-MM-DD");

    setPatientData((prevState) => ({
      ...prevState,
      [type]: formattedDate,
    }));

    calculateAge(formattedDate);
  };

  const handleTimeChange = (time) => {
    console.log(time);
    const currentTime = new Date(time.$d);

    setPatientData({
      ...patientData,
      birthTime: currentTime,
    });
  };

  const validateFields = () => {
    const errors = {};
    if (!patientData.firstName) {
      errors.firstName = "First Name is required";
    }
    if (!patientData.lastName) {
      errors.lastName = "Last Name is required";
    }
    if (!patientData.ageGroup) {
      errors.ageGroup = "Age Group is required";
    }
    if (patientData.gender === "") {
      errors.gender = "Gender is required";
    }
    if (!patientData.birthDate) {
      errors.birthDate = "Birth Date is required";
    }
    if (!/^\d{10}$/.test(patientData.mobileNumber)) {
      errors.mobileNumber = "Mobile Number should be 10 digits";
    }
    if (patientData.weight && isNaN(parseFloat(patientData.weight))) {
      errors.weight = "Weight should be a valid number";
    }
    if (patientData.email && !/^\S+@\S+\.\S+$/.test(patientData.email)) {
      errors.email = "Invalid email format";
    }

    setValidateError(errors);
    return Object.keys(errors).length === 0;
  };

  // add patient data save
  const handleAddPatientSubmit = (ele) => {
    ele.preventDefault();

    if (validateFields()) {
      setLoading(true);
      const postData = async () => {
        const sendData = await addPatientData(patientData);
        console.log("sendData", sendData);
        if (sendData.success === true) {
          navigate("/patientlist");
        }
      };
      postData();
    }
  };

  const handleCancel = () => {
    navigate("/patientlist");
  };

  // edit patient details
  const [editPatient, setEditPatient] = useState("");
  useEffect(() => {
    if (patientId) {
      // getting single patient Data
      const getData = async () => {
        try {
          const data = await getSinglePatientData(patientId);
          const hasPhotos =
            Array.isArray(data.photos) && data.photos.length > 0;
          console.log("hasPhotos: ", hasPhotos);
          setIsLoading(!hasPhotos);
          if (hasPhotos === false) {
            setIsLoading(false);
          }
          setEditPatient(data);
        } catch (error) {
          console.error("Error fetching single patient data:", error);
        }
      };
      getData();
    }
  }, [patientId]);

  // edit patient data and update
  const patientDataEdit = () => {
    setLoading(true);
    const updateData = async () => {
      const update = await updatePatientData(patientId, editPatient);
      if (update.success === true) {
        navigate("/patientlist");
      }
      setLoading(false);
    };
    updateData();
  };

  // edit patient textField Value change
  const handleEditInput = (ele) => {
    let editUpdatedData;

    if (ele.target.name === "ageYear" || ele.target.name === "ageMonth") {
      editUpdatedData = {
        ...editPatient,
        birthDate: null,
        [ele.target.name]: ele.target.value,
      };
    } else {
      editUpdatedData = {
        ...editPatient,
        [ele.target.name]: ele.target.value,
      };
    }
    setEditPatient(editUpdatedData);
    if (ele.target.name === "birthDate") {
      calculateAge(moment(ele.target.value));
    }
  };

  const [findPrakrutiData, setFindPrakrutiData] = useState([]);

  const handleRadioChange = (questionId, type, checked) => {

    console.log(questionId);
    console.log(type);

    const newDataJson = [...dataJson];
    newDataJson[questionId]["vata"] = 0;
    newDataJson[questionId]["pitta"] = 0;
    newDataJson[questionId]["kapha"] = 0;
    newDataJson[questionId][type] = 1;

    setDataJson(newDataJson);

    const isIdAlreadyExists = findPrakrutiData.some(
      (item) => item.questionId === questionId
    );

    if (!isIdAlreadyExists) {
      setFindPrakrutiData([...findPrakrutiData, { questionId, type }]);
    } else {
      const updatedData = findPrakrutiData.map((item) =>
        item.questionId === questionId ? { ...item, type } : item
      );
      setFindPrakrutiData(updatedData);
    }
  };

  console.log("findPrakrutiData", findPrakrutiData);

  useEffect(() => {
    // Calculate the total counts for each type
    let vataTotal = 0;
    let pittaTotal = 0;
    let kaphaTotal = 0;

    dataJson.forEach((item) => {
      vataTotal += item.vata;
      pittaTotal += item.pitta;
      kaphaTotal += item.kapha;
    });

    // Set the counts in the state
    setVataCount(vataTotal);
    setPittaCount(pittaTotal);
    setKaphaCount(kaphaTotal);
  }, [dataJson]);

  let totalVata = 0;
  let totalpitta = 0;
  let totalKapha = 0;

  // Loop through the array and calculate the sums
  for (const item of dataJson) {
    totalVata += item.vata;
    totalpitta += item.pitta;
    totalKapha += item.kapha;
  }

  const sum = totalVata + totalpitta + totalKapha;

  const isAnyGroupBlank = () => {
    for (const group of dataJson) {
      if (sum < initialDataJson.length) {
        return true;
      }
    }
    return false;
  };

  const handlePrakruti = () => {
    if (isAnyGroupBlank()) {
      setSnackbarOpen(true);
    } else {
      let maxField = "vata";
      let maxCount = vataCount;

      if (pittaCount > maxCount) {
        maxField = "pitta";
        maxCount = pittaCount;
      }

      if (kaphaCount > maxCount) {
        maxField = "kapha";
        maxCount = kaphaCount;
      }

      localStorage.setItem("maxPrakrutiField", maxField);

      setPatientData((prevData) => ({
        ...prevData,
        prakruti: maxField,
      }));

      const prakrutiFormData = {
        type: "PATIENT_PRAKRUTI",
        formData: findPrakrutiData,
      };

      setPatientData((prevData) => ({
        ...prevData,
        formData: [...prevData.formData, prakrutiFormData],
      }));

      setOpen(false);
    }
  };

  // roga pariksha radio button change in dialog box
  const rogaParikshaRadioChange = (event, ele) => {
    const { value } = event.target;

    setSelectedValues((prevValues) => ({
      ...prevValues,
      [ele.id]: {
        ...prevValues[ele.id],
        radio: value,
      },
    }));
  };

  const [ageInYear, setAgeInYear] = useState("");
  const [ageInMonth, setAgeInMonth] = useState("");

  // counting age and year
  const calculateAge = (date) => {
    if (date) {
      const birthDate = moment(date);
      const today = moment();

      const years = today.diff(birthDate, "years");
      birthDate.add(years, "years");
      const months = today.diff(birthDate, "months");
      setAgeInMonth(months);
      setAgeInYear(years);

      return "N/A";
    }
    return "N/A";
  };

  useEffect(() => {
    setPatientData({
      ...patientData,
      ageYear: ageInYear,
      ageMonth: ageInMonth,
    });

    setEditPatient({
      ...editPatient,
      ageYear: ageInYear,
      ageMonth: ageInMonth,
    });
  }, [ageInYear, ageInMonth]);

  const [image, setImage] = useState("");

  const handleDropImage = (files) => {
    const droppedImage = files[0];

    if (droppedImage) {
      if (droppedImage.size <= 5 * 1024 * 1024) {
        setImage(droppedImage);
        const newImage = {
          imageFile: droppedImage,
        };
        setPatientData((prevData) => ({
          ...prevData,
          photos: [...prevData.photos, newImage],
        }));
      } else {
        alert(
          "Selected image exceeds the 5MB limit. Please choose a smaller image."
        );
      }
    }
  };

  // upload image
  const webcamRef = useRef(null);
  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [videoDialogOpen, setVideoDialogOpen] = useState(false);
  const [imgSrc, setImgSrc] = useState(null);
  const [showCamera, setShowCamera] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [videoError, setVideoError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const capture = useCallback(() => {
    try {
      const imageSrc = webcamRef.current.getScreenshot();
      console.log("Captured image:", imageSrc);
      setImgSrc(imageSrc);
      setShowCamera(false);
    } catch (error) {
      console.error("Error capturing image:", error);
    }
  }, [webcamRef, setImgSrc, setShowCamera]);

  const retake = () => {
    setImgSrc(null);
    setShowCamera(true);
  };

  const toggleCamera = () => {
    setShowCamera(!showCamera);
  };

  const handleSaveImage = () => {
    setSaveLoading(true);
    let formData;

    if (!imgSrc || image) {
      console.log("hello World");
      formData = new FormData();
      formData.append("file", image);
    } else {
      const base64String = imgSrc.split(",")[1];
      const byteArray = new Uint8Array(
        [...atob(base64String)].map((char) => char.charCodeAt(0))
      );
      const blob = new Blob([byteArray], { type: "image/png" });
      formData = new FormData();
      formData.append("file", blob, "captured_image.png");
    }

    const uploadImageData = async () => {
      const imageData = await uploadImage(formData);
      if (imageData) {
        setImgSrc("");
        setImage("");
        setImageDialogOpen(false);
        if (editPatient._id) {
          setEditPatient((prevData) => ({
            ...prevData,
            photos: [imageData.data.fileUrl],
          }));
        } else {
          setPatientData((prevData) => ({
            ...prevData,
            photos: [imageData.data.fileUrl],
          }));
        }
        setSaveLoading(false);
      } else {
        setImageDialogOpen(true);
        setImageError(true);
      }
      setSaveLoading(false);
    };
    uploadImageData();
  };

  const [localVideo, setLocalVideo] = useState(null);

  const handleDropVideo = (files) => {
    const droppedVideo = files[0];

    if (droppedVideo) {
      if (droppedVideo.size <= 15 * 1024 * 1024) {
        setLocalVideo(droppedVideo);
        setVideoError(false);
      } else {
        alert(
          "Selected Video exceeds the 15MB limit. Please choose a smaller Video."
        );
      }
    }
  };

  const { startRecording, stopRecording, mediaBlobUrl, previewStream } =
    useReactMediaRecorder({ video: true });

  const videoRef = useRef(null);

  useEffect(() => {
    if (previewStream && videoRef.current) {
      videoRef.current.srcObject = previewStream;
    }
  }, [previewStream]);

  const handleSaveVideo = async () => {
    if (localVideo) {
      console.log(localVideo);
      const formData = new FormData();
      formData.append("file", localVideo);
      setLocalVideo(null);
      videoUploadApi(formData);
    } else {
      const videoData = new FormData();
      if (mediaBlobUrl instanceof Blob) {
        videoData.append("file", mediaBlobUrl, "video.mp4");
      } else if (typeof mediaBlobUrl === "string") {
        try {
          const response = await fetch(mediaBlobUrl);
          const blob = await response.blob();
          videoData.append("file", blob, "video.mp4");
        } catch (error) {
          console.error("Error fetching Blob from URL:", error);
          return;
        }
      }
      videoUploadApi(videoData);
    }
    setSaveLoading(true);
  };

  const videoUploadApi = (videoData) => {
    const uploadVideoData = async () => {
      const data = await uploadVideo(videoData);
      if (data) {
        setPatientData((prevData) => ({
          ...prevData,
          videos: [data.data.fileUrl],
        }));
        setVideoDialogOpen(false);
        setVideoError(false);
        setSaveLoading(false);
      } else {
        setVideoDialogOpen(true);
        setVideoError(true);
      }
      setSaveLoading(false);
    };
    uploadVideoData();
  };

  const [vitData, setVitData] = useState(null);
  useEffect(() => {
    if (patientId) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${baseUrl.url}/api/formdata/form?id=${patientId}&type=VITAL_CRITERIA`,
        headers: {
          token: getToken(),
          "Content-Type": "application/json",
        },
      };

      axios
        .request(config)
        .then((response) => {
          setVitData(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const [patientPrakrutiData, setPatientPrakrutiData] = useState(null);
  useEffect(() => {
    if (patientId) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${baseUrl.url}/api/formdata/form?id=${patientId}&type=PATIENT_PRAKRUTI`,
        headers: {
          token: getToken(),
          "Content-Type": "application/json",
        },
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(JSON.stringify(response.data));
          setPatientPrakrutiData(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const [patientHetuParikshaData, setPatientHetuParikshaData] = useState(null);
  useEffect(() => {
    if (patientId) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${baseUrl.url}/api/formdata/form?id=${patientId}&type=HETU_PARIKSHA`,
        headers: {
          token: getToken(),
          "Content-Type": "application/json",
        },
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(JSON.stringify(response.data));
          setPatientHetuParikshaData(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  return (
    <>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert severity="error">One or more groups are blank !</Alert>
      </Snackbar>
      {/* AddPatient Page Start */}
      <Box>
        <Header />
        <Box sx={dashboard}>
          <Box padding="20px">
            <Box>
              <Breadcrumbs>
                {user === "admin" ? (
                  <Breadcrumbs>
                    <Link style={linkStyle} to="/patientlist">
                      Patient
                    </Link>
                    <Link style={linkStyle} underline="hover" to="/addpatient">
                      Add Patient
                    </Link>
                  </Breadcrumbs>
                ) : (
                  <Breadcrumbs>
                    <Link style={linkStyle} to="/dashboard">
                      Dashboard
                    </Link>
                    <Link style={linkStyle} to="/patientlist">
                      Patient
                    </Link>
                    <Link style={linkStyle} underline="hover" to="/addpatient">
                      Add Patient
                    </Link>
                  </Breadcrumbs>
                )}
              </Breadcrumbs>

              <Typography sx={mainHeading}>
                {patientId ? "Edit Patient Details" : "Patient Details"}
              </Typography>
            </Box>
            <Divider
              sx={{ height: 1, backgroundColor: "Black", marginY: "20px" }}
            />
            <Box>
              <Grid container>
                <Grid md={10}>
                  <Grid md={12} display="flex">
                    <Grid md={4} mr={1}>
                      <TextField
                        sx={{ width: "94%", mt: "10px" }}
                        placeholder="First Name"
                        name="firstName"
                        variant="outlined"
                        onChange={
                          patientId ? handleEditInput : handleAddPatient
                        }
                        value={editPatient.firstName}
                        label={patientId ? "" : "First Name"}
                        error={!!validateError.firstName}
                        helperText={validateError.firstName}
                      />
                    </Grid>
                    <Grid md={4} mr={1}>
                      <TextField
                        sx={{ width: "94%", mt: "10px" }}
                        placeholder="Middle Name(Optional)"
                        name="middleName"
                        onChange={
                          patientId ? handleEditInput : handleAddPatient
                        }
                        value={editPatient.middleName}
                        label={patientId ? "" : "Middle Name(Optional)"}
                      />
                    </Grid>
                    <Grid md={4}>
                      <TextField
                        sx={{ width: "100%", mt: "10px" }}
                        placeholder="Last Name"
                        name="lastName"
                        onChange={
                          patientId ? handleEditInput : handleAddPatient
                        }
                        value={editPatient.lastName}
                        label={patientId ? "" : "Last Name"}
                        error={!!validateError.lastName}
                        helperText={validateError.lastName}
                      />
                    </Grid>
                  </Grid>
                  <Grid md={12} mt={2}>
                    <Box>
                      <TextField
                        multiline
                        sx={{ width: "100%" }}
                        name="address"
                        onChange={
                          patientId ? handleEditInput : handleAddPatient
                        }
                        value={editPatient.address}
                        label={patientId ? "" : "Address(Optional)"}
                        placeholder="Address(Optional)"
                      />
                    </Box>
                  </Grid>
                  <Grid md={12} display="flex">
                    <Grid md={4}>
                      <TextField
                        sx={{ width: "94%", mt: 2 }}
                        name="mobileNumber"
                        placeholder="Mobile Number"
                        onChange={
                          patientId ? handleEditInput : handleAddPatient
                        }
                        value={editPatient.mobileNumber}
                        label={patientId ? "" : "Mobile Number"}
                        error={!!validateError.mobileNumber}
                        helperText={validateError.mobileNumber}
                      />
                    </Grid>
                    <Grid md={4}>
                      <TextField
                        sx={{ width: "94%", mt: 2 }}
                        name="email"
                        placeholder="Email(Optional)"
                        onChange={
                          patientId ? handleEditInput : handleAddPatient
                        }
                        value={editPatient.email}
                        label={patientId ? "" : "Email(Optional)"}
                        error={validateError.email ? true : false}
                        helperText={validateError.email}
                      />
                    </Grid>
                    <Grid md={4}>
                      <TextField
                        sx={{ width: "100%", mt: 2 }}
                        name="idNumber"
                        onChange={
                          patientId ? handleEditInput : handleAddPatient
                        }
                        value={editPatient.idNumber}
                        label={patientId ? "" : "Any ID Number(Optional)"}
                        placeholder="Any ID Number(Optional)"
                      />
                    </Grid>
                  </Grid>
                  <Grid md={12} display="flex">
                    <Grid md={4}>
                      <TextField
                        sx={{ width: "94%", mt: 2 }}
                        name="weight"
                        placeholder="Weight(Optional)"
                        onChange={
                          patientId ? handleEditInput : handleAddPatient
                        }
                        value={editPatient.weight}
                        label={patientId ? "" : "Weight(Optional)"}
                        error={validateError.weight ? true : false}
                        helperText={validateError.weight}
                      />
                    </Grid>
                    <Grid md={4} mt={2}>
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          Gender
                        </FormLabel>
                        <RadioGroup
                          onChange={
                            patientId ? handleEditInput : handleAddPatient
                          }
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="gender"
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <FormControlLabel
                            value="female"
                            control={<Radio />}
                            label="Female"
                            checked={
                              patientId
                                ? editPatient.gender === "female"
                                : patientData.gender === "female"
                            }
                          />
                          <FormControlLabel
                            value="male"
                            control={<Radio />}
                            label="Male"
                            checked={
                              patientId
                                ? editPatient.gender === "male"
                                : patientData.gender === "male"
                            }
                          />
                          <FormControlLabel
                            value="other"
                            control={<Radio />}
                            label="Other"
                            checked={
                              patientId
                                ? editPatient.gender === "other"
                                : patientData.gender === "other"
                            }
                          />
                        </RadioGroup>
                        <FormHelperText sx={{ color: "#d32f2f" }}>
                          {validateError.gender}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid md={4} mt={2}>
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          Age
                        </FormLabel>
                        <RadioGroup
                          style={{ width: "max-content" }}
                          onChange={
                            patientId ? handleEditInput : handleAddPatient
                          }
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="ageGroup"
                          value={
                            patientId
                              ? editPatient.ageGroup
                              : patientData.ageGroup
                          }
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <FormControlLabel
                            value="child"
                            control={<Radio />}
                            label="Child"
                            checked={
                              patientId
                                ? editPatient.ageGroup === "child"
                                : patientData.ageGroup === "child"
                            }
                          />
                          <FormControlLabel
                            value="adult"
                            control={<Radio />}
                            label="Adult"
                            checked={
                              patientId
                                ? editPatient.ageGroup === "adult"
                                : patientData.ageGroup === "adult"
                            }
                          />
                          <FormControlLabel
                            value="geriatric(above 60)"
                            control={<Radio />}
                            label="Geriatric(above 60)"
                            checked={
                              patientId
                                ? editPatient.ageGroup === "geriatric(above 60)"
                                : patientData.ageGroup === "geriatric(above 60)"
                            }
                          />
                        </RadioGroup>
                        <FormHelperText sx={{ color: "#d32f2f" }}>
                          {validateError.ageGroup}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid md={12} display="flex">
                    <Grid md={3} mt="2px">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DatePicker"]}>
                          <DatePicker
                            sx={{
                              width: "94%",
                            }}
                            label="Birth Date"
                            format="DD-MM-YYYY"
                            slotProps={{
                              textField: {
                                helperText: validateError.birthDate,
                                error: validateError.birthDate,
                              },
                            }}
                            value={
                              patientId
                                ? dayjs(
                                    moment(editPatient?.birthDate).format(
                                      "YYYY-MM-DD"
                                    )
                                  )
                                : patientData.birthDate
                            }
                            onChange={
                              patientId
                                ? (newDate) =>
                                    handleEditInput({
                                      target: {
                                        name: "birthDate",
                                        value: newDate
                                          ? newDate.format("YYYY-MM-DD")
                                          : "",
                                      },
                                    })
                                : (newDate) =>
                                    handleDatePicker("birthDate", newDate)
                            }
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Grid>
                    <Grid md={3} mt="8px">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["TimePicker"]}>
                          <TimePicker
                            sx={{ width: "94%" }}
                            label="Birth Time"
                            // onChange={(newTime) => handleTimeChange(newTime)}
                            onChange={
                              patientId
                                ? (newTime) => {
                                    const formattedTime = newTime
                                      ? newTime
                                      : null;
                                    setEditPatient((prevState) => ({
                                      ...prevState,
                                      birthTime: formattedTime
                                        ? formattedTime.toDate()
                                        : null,
                                    }));
                                  }
                                : (newTime) => handleTimeChange(newTime)
                            }
                            value={
                              patientId ? dayjs(editPatient.birthTime) : null
                            }
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Grid>
                    <Grid md={3} mt="8px">
                      <TextField
                        sx={{ width: "100%" }}
                        onChange={
                          patientId ? handleEditInput : handleAddPatient
                        }
                        value={editPatient.birthPlace}
                        label={patientId ? "" : "Birth Place(Optional)"}
                        name="birthPlace"
                        placeholder="Birth Place(Optional)"
                      />
                    </Grid>
                    <Grid md={3}>
                      <TextField
                        sx={{ width: "95%", mt: "8px", ml: "15px" }}
                        placeholder="Age in Year(Optional)"
                        InputLabelProps={{ shrink: true }}
                        name="ageYear"
                        onChange={
                          patientId ? handleEditInput : handleAddPatient
                        }
                        value={
                          patientId ? editPatient.ageYear : patientData.ageYear
                        }
                        label={patientId ? "" : "Age in Year(Optional)"}
                      />
                    </Grid>
                  </Grid>
                  <Grid md={12} display="flex">
                    <Grid md={3}>
                      <TextField
                        sx={{ width: "94%", mt: 2 }}
                        placeholder="Age in Month(Optional)"
                        InputLabelProps={{ shrink: true }}
                        name="ageMonth"
                        onChange={
                          patientId ? handleEditInput : handleAddPatient
                        }
                        value={
                          patientId
                            ? editPatient.ageMonth
                            : patientData.ageMonth
                        }
                        label={patientId ? "" : "Age in Month(Optional)"}
                      />
                    </Grid>
                    <Grid lg={3}>
                      <TextField
                        sx={{ width: "94%", mt: 2 }}
                        onChange={
                          patientId ? handleEditInput : handleAddPatient
                        }
                        value={editPatient.refName}
                        label={patientId ? "" : "Ref. Name(Optional)"}
                        name="refName"
                        placeholder="Ref. Name(Optional)"
                      />
                    </Grid>
                    <Grid lg={3}>
                      <TextField
                        sx={{ width: "100%", mt: 2 }}
                        onChange={
                          patientId ? handleEditInput : handleAddPatient
                        }
                        value={editPatient.refMobileNo}
                        label={patientId ? "" : "Ref. Mobile No(Optional)"}
                        name="refMobileNo"
                        placeholder="Ref. Mobile No(Optional)"
                        maxLength={5}
                      />
                    </Grid>
                    <Grid lg={3}>
                      <FormControl
                        fullWidth
                        sx={{ width: "95%", mt: 2, ml: "15px" }}
                      >
                        <InputLabel id="demo-simple-select-label">
                          Ref. Media(Optional)
                        </InputLabel>
                        <Select
                          sx={{ width: "95%" }}
                          // value={
                          //   patientId
                          //     ? editPatient.refMedia
                          //     : patientData.refMedia
                          // }
                          value={editPatient.refMedia || patientData.refMedia}
                          label="Ref. Media(Optional)"
                          name="refMedia"
                          onChange={
                            patientId ? handleEditInput : handleAddPatient
                          }
                        >
                          <MenuItem value={"Instagram"}>Instagram</MenuItem>
                          <MenuItem value={"Facebook"}>Facebook</MenuItem>
                          <MenuItem value={"Youtube"}>Youtube</MenuItem>
                          <MenuItem value={"Whatsapp"}>Whatsapp</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid md={2}>
                  <Box display="flex" flexDirection="column">
                    <Typography sx={imgHeading}>Patient Photo</Typography>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      marginBottom="20px"
                      flexDirection="column"
                    >
                      {patientId && (
                        <>
                          {isLoading ? (
                            <ClipLoader color="#2F7539" />
                          ) : (
                            <>
                              {editPatient.photos?.length > 0 ? (
                                <Button
                                  sx={uploadFilesStyle}
                                  variant="contained"
                                  component="label"
                                  onClick={() => setImageDialogOpen(true)}
                                >
                                  <img
                                    alt="patient-img"
                                    height={"100px"}
                                    width={"100px"}
                                    src={editPatient.photos[0]}
                                  />
                                </Button>
                              ) : (
                                <Button
                                  sx={uploadFilesStyle}
                                  variant="contained"
                                  component="label"
                                  onClick={() => setImageDialogOpen(true)}
                                >
                                  <img
                                    height={"100px"}
                                    width={"100px"}
                                    alt="patient-img"
                                    src={require("../../assets/photo.png")}
                                  />
                                </Button>
                              )}
                            </>
                          )}
                        </>
                      )}

                      {!patientId && (
                        <>
                          {patientData.photos.length > 0 ? (
                            <Button
                              sx={uploadFilesStyle}
                              variant="contained"
                              component="label"
                              onClick={() => setImageDialogOpen(true)}
                            >
                              <img
                                alt="patient-img"
                                height={"100px"}
                                width={"100px"}
                                src={patientData.photos[0]}
                              />
                            </Button>
                          ) : (
                            <Button
                              sx={uploadFilesStyle}
                              variant="contained"
                              component="label"
                              onClick={() => setImageDialogOpen(true)}
                            >
                              <img
                                alt="patient-img"
                                height={"100px"}
                                width={"100px"}
                                src={require("../../assets/photo.png")}
                              />
                            </Button>
                          )}
                        </>
                      )}
                    </Box>

                    <Typography sx={imgHeading}>Patient Video</Typography>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      marginBottom="20px"
                      flexDirection="column"
                    >
                      <Button
                        sx={uploadFilesStyle}
                        variant="contained"
                        component="label"
                        onClick={() => setVideoDialogOpen(true)}
                      >
                        <img
                          alt="patient-video"
                          style={{
                            backgroundColor: "#D9D9D9",
                            padding: "20px",
                          }}
                          src={require("../../assets/video.png")}
                        />
                      </Button>
                    </Box>
                    <Box display="flex" justifyContent="center">
                      {patientData.videos.length > 0 ? (
                        <Tooltip
                          title={patientData.videos[0].split("/").pop()}
                          arrow
                        >
                          <Link
                            style={{
                              textDecoration: "none",
                              color: "green",
                              fontWeight: "bold",
                            }}
                            to={patientData.videos[0]}
                            target="_blank"
                          >
                            {patientData.videos[0]
                              .split("/")
                              .pop()
                              .slice(0, 12)}
                          </Link>
                        </Tooltip>
                      ) : null}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Box display="flex" mt={2} alignItems={"center"}>
                <Box>
                  <Typography sx={dialogBoxName} onClick={() => setOpen(true)}>
                    Patient Prakruti
                  </Typography>
                </Box>
                <Box>
                  {isVatapittaKapha ? (
                    <DoneAllIcon sx={{ color: "#2f7539" }} />
                  ) : null}
                </Box>
                <Box>
                  <Typography
                    sx={dialogBoxName}
                    onClick={() => {
                      setOpenVitalCriteria(true);
                    }}
                  >
                    Vital Criteria
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={dialogBoxName}
                    onClick={() => setOpenGynecDialog(true)}
                  >
                    Gynec & Obstetrics History
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={dialogBoxName}
                    onClick={() => setHetuParikshaOpen(true)}
                  >
                    Hetu Pariksha
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={dialogBoxName}
                    onClick={() => setRogaParikshaOpen(true)}
                  >
                    Roga Pariksha
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" margin="0px">
                <Button
                  sx={saveButton}
                  variant="contained"
                  onClick={patientId ? patientDataEdit : handleAddPatientSubmit}
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : patientId ? (
                    "Update"
                  ) : (
                    "Save"
                  )}
                </Button>
                <Button
                  onClick={handleCancel}
                  sx={cancelButton}
                  variant="outlined"
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <div style={{ position: "fixed", bottom: 0, width: "25%", zIndex: 2000 }}>
        {imageError && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            Please capture or upload the image <strong>check it out!</strong>
          </Alert>
        )}
      </div>
      <div style={{ position: "fixed", bottom: 0, width: "25%", zIndex: 2000 }}>
        {videoError && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            Please capture or upload the video <strong>check it out!</strong>
          </Alert>
        )}
      </div>

      {/* Patient Pakruti Dialog Box Start */}
      <PatientPrakrutiPage
        open={open}
        dialogHeading={dialogHeading}
        setOpen={setOpen}
        clearButton={clearButton}
        dialogBoxStyle={dialogBoxStyle}
        vataCount={vataCount}
        pittaCount={pittaCount}
        kaphaCount={kaphaCount}
        setVataCount={setVataCount}
        setPittaCount={setPittaCount}
        setKaphaCount={setKaphaCount}
        prakrutiData={prakrutiData}
        boxStyle={boxStyle}
        boxHeading={boxHeading}
        labelStyle={labelStyle}
        handleRadioChange={handleRadioChange}
        boxContent={boxContent}
        boxContentMain={boxContentMain}
        saveButton={saveButton}
        handlePrakruti={handlePrakruti}
        cancelButton={cancelButton}
        patientPrakrutiData={patientPrakrutiData}
        setPatientPrakrutiData={setPatientPrakrutiData}
        patientId={patientId}
        setEditPatient={setEditPatient}
        editPatient={editPatient}
      />

      {/* Vital Criteria Dialog Start */}
      <VitalCriteriaPage
        openVitalCriteria={openVitalCriteria}
        setOpenVitalCriteria={setOpenVitalCriteria}
        saveButton={saveButton}
        cancelButton={cancelButton}
        setPatientData={setPatientData}
        setEditPatient={setEditPatient}
        patientId={patientId}
        vitData={vitData}
        setVitData={setVitData}
      />

      {/* Gynec & Obstetrics History Dialog Start */}
      <GynecPage
        openGynecDialog={openGynecDialog}
        saveButton={saveButton}
        cancelButton={cancelButton}
        setOpenGynecDialog={setOpenGynecDialog}
      />

      {/* Patient Hetu Pariksha  Dialog Box Start */}
      <HetuParikshaPage
        patientId={patientId}
        hetuParikshaOpen={hetuParikshaOpen}
        dialogHeading={dialogHeading}
        setHetuParikshaOpen={setHetuParikshaOpen}
        clearButton={clearButton}
        hetuParikshaData={hetuParikshaData}
        patientHetuParikshaData={patientHetuParikshaData}
        setPatientHetuParikshaData={setPatientHetuParikshaData}
        selectedValues={selectedValues}
        rogaParikshaRadioChange={rogaParikshaRadioChange}
        selectExcercis={selectExcercis}
        saveButton={saveButton}
        cancelButton={cancelButton}
        setPatientData={setPatientData}
        setEditPatient={setEditPatient}
        editPatient={editPatient}
      />

      <RogaParikshaPage
        rogaParikshaOpen={rogaParikshaOpen}
        dialogHeading={dialogHeading}
        setRogaParikshaOpen={setRogaParikshaOpen}
        clearButton={clearButton}
        rogaParikshaData={rogaParikshaData}
        selectedValues={selectedValues}
        rogaParikshaRadioChange={rogaParikshaRadioChange}
        selectExcercis={selectExcercis}
        saveButton={saveButton}
        cancelButton={cancelButton}
      />

      {/* upload image Dialog */}
      <Dialog
        open={imageDialogOpen}
        maxWidth="md"
        fullWidth={true}
        onClose={() => setImageDialogOpen(false)}
      >
        <DialogTitle>
          <Typography style={dropTitleStyle}>Upload Image</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container display={"flex"} justifyContent={"space-evenly"}>
            <Grid md={5} marginY={"10px"}>
              <Typography style={dropTitleStyle}>Drop files here</Typography>
              <DropzoneArea
                id="images"
                style={dropContainerStyle}
                acceptedFiles={["image/*"]}
                dropzoneText={
                  <span
                    style={{
                      fontSize: "16px",
                      color: "#333",
                      fontWeight: "bold",
                      textAlign: "center",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "80px",
                    }}
                  >
                    Drag and drop an image here or click
                  </span>
                }
                onChange={handleDropImage}
              />
            </Grid>
            <Grid md={5} marginY={"10px"}>
              <Typography style={dropTitleStyle}>Take Image</Typography>
              {showCamera ? (
                <Webcam width={"100%"} ref={webcamRef} />
              ) : imgSrc ? (
                <>
                  <img src={imgSrc} alt="webcam" />
                </>
              ) : null}

              <div className="btn-container">
                {showCamera ? (
                  <>
                    <Button
                      sx={imageButton}
                      variant="contained"
                      onClick={capture}
                    >
                      <MdCamera /> &nbsp; Capture photo
                    </Button>
                  </>
                ) : imgSrc ? (
                  <Button sx={imageButton} variant="contained" onClick={retake}>
                    <MdFlipCameraIos /> &nbsp; Retake photo
                  </Button>
                ) : null}

                {showCamera ? (
                  <Button
                    sx={imageButton}
                    variant="contained"
                    onClick={toggleCamera}
                  >
                    <MdCancel /> &nbsp; Close Camera
                  </Button>
                ) : (
                  <Button
                    sx={imageButton}
                    variant="contained"
                    onClick={toggleCamera}
                  >
                    <FaCamera /> &nbsp; Open Camera
                  </Button>
                )}
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Stack pt={2} direction="row">
            <Button
              sx={saveButton}
              variant="contained"
              disabled={saveLoading}
              onClick={() => handleSaveImage()}
            >
              {saveLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Save"
              )}
            </Button>
            <Button
              sx={cancelButton}
              variant="outlined"
              onClick={() => {
                setImageDialogOpen(false);
                setImageError(false);
              }}
            >
              Cancel
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      {/* upload Video Dialog */}

      <Dialog
        open={videoDialogOpen}
        maxWidth="md"
        fullWidth={true}
        onClose={() => setVideoDialogOpen(false)}
      >
        <DialogTitle>
          <Typography style={dropTitleStyle}>Upload Video</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container display={"flex"} justifyContent={"space-evenly"}>
            <Grid md={5} marginY={"10px"}>
              <Typography style={dropTitleStyle}>Drop files here</Typography>
              <Box>
                <DropzoneArea
                  id="video"
                  style={dropContainerStyle}
                  acceptedFiles={["video/*"]}
                  dropzoneText={
                    <span
                      style={{
                        fontSize: "16px",
                        color: "#333",
                        fontWeight: "bold",
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "80px",
                      }}
                    >
                      Drag and drop an Video here or click
                    </span>
                  }
                  onChange={handleDropVideo}
                  maxFileSize={15000000} // 15MB in bytes (1 MB = 1000000 bytes)
                />
              </Box>
            </Grid>
            <Grid md={5} marginY={"10px"}>
              <Typography style={dropTitleStyle}>Take Video</Typography>
              <div>
                {/* <p>{status}</p> */}
                <Box display="flex" mb={2}>
                  <Box display="flex" mr={2}>
                    <Button
                      onClick={startRecording}
                      variant="contained"
                      color="success"
                      sx={{ fontSize: "16px" }}
                    >
                      <BiVideoRecording style={{ fontSize: "16px" }} />
                      <Typography> &nbsp; Start</Typography>
                    </Button>
                  </Box>
                  <Box display="flex">
                    <Button
                      onClick={stopRecording}
                      variant="contained"
                      color="success"
                    >
                      <FaRegCircleStop style={{ fontSize: "16px" }} />
                      <Typography> &nbsp; Stop</Typography>
                    </Button>
                  </Box>
                </Box>
                <video width="100%" src={mediaBlobUrl} controls autoPlay loop />
                <video ref={videoRef} width="100%" autoPlay controls />
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box display="flex" margin="0px">
            <Button
              sx={saveButton}
              variant="contained"
              onClick={() => handleSaveVideo()}
              disabled={saveLoading}
            >
              {saveLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Save"
              )}
            </Button>
            <Button
              onClick={() => {
                setVideoDialogOpen(false);
                setVideoError(false);
              }}
              sx={cancelButton}
              variant="outlined"
            >
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AddPatient;
